import { Navigate, Outlet } from "react-router-dom";
import Header from "../components/layout/Header";
import { ToastContainerNotification } from "../utils/notifications";

const PublicRoutes = () => {
  const isAuthenticated = localStorage.getItem("access_token");
  const isStoreLinked = localStorage.getItem("store");

  if (isAuthenticated && isStoreLinked) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <Header />
      <Outlet />
      <ToastContainerNotification />
    </>
  );
};

export default PublicRoutes;
