import { useQuery } from "react-query";
import api from "../../utils/axios";
import { useAuth } from "../../context/authContext";
import CustomLoader from "../../components/Loader";
import moment from "moment";
import { useState } from "react";

type PaginationProps = {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  paginatedData: any;
};
const itemsPerPage = 10;
const Pagination = ({
  currentPage,
  setCurrentPage,
  paginatedData,
}: PaginationProps) => {
  const hanldePrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };
  return (
    <nav aria-label="Page navigation example">
      <div className="flex items-center -space-x-px h-8 text-sm">
        <button disabled={currentPage === 1} onClick={hanldePrevious}>
          <a
            href="#"
            className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 "
          >
            <span className="sr-only">Previous</span>
            <svg
              className="w-2.5 h-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
          </a>
        </button>
        <span>
          <a
            href="#"
            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            {currentPage}
          </a>
        </span>
        <button
          onClick={handleNext}
          disabled={paginatedData?.length < itemsPerPage}
        >
          <a
            href="#"
            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
          >
            <span className="sr-only">Next</span>
            <svg
              className="w-2.5 h-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </a>
        </button>
      </div>
    </nav>
  );
};

const _customLabel = (text: any, type: string) => {
  if (!text) {
    return;
  }
  const _color =
    type === "SUCCESS"
      ? "bg-green-500"
      : type === "ERROR"
      ? "bg-red-500"
      : "bg-yellow-300";
  const _style = `${_color} px-3 py-1 text-white rounded-full text-xs`;
  return <span className={_style}>{text}</span>;
};

export const OrdersHistoryPage = () => {
  const { authUser } = useAuth();
  const { shop } = authUser;
  const [currentPage, setCurrentPage] = useState(1);

  const { data: orders, isLoading } = useQuery({
    queryKey: ["Orders history", shop],
    queryFn: async () => {
      const resp = await api.get(`/orders?shop=${shop}`);
      if (resp?.data?.CODE === "SUCCESS") {
        return resp?.data.orders;
      }
      return [];
    },
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = orders?.slice(startIndex, endIndex);

  const showPagination = orders && orders.length > itemsPerPage;

  if (isLoading) return <CustomLoader />;
  return (
    <div className="flex justify-center items-center flex-col h-full ">
      <div className="w-full ">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-black dark:text-gray-400">
            <thead className="text-xs text-black uppercase bg-gray-50">
              <tr className="bg-secondary">
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Time
                </th>
                <th scope="col" className="px-6 py-3">
                  Order Number
                </th>
                <th scope="col" className="px-6 py-3">
                  price
                </th>
                <th scope="col" className="px-6 py-3">
                  Address Status
                </th>
                <th scope="col" className="px-6 py-3">
                  store
                </th>
              </tr>
            </thead>
            <tbody className="text-black">
              {paginatedData?.length > 0
                ? paginatedData.map((order: any, index: number) => {
                    return (
                      <tr className="bg-white border-b" key={order._id}>
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium  whitespace-nowrap "
                        >
                          {moment(order.createdAt).format("DD-MM-YYYY")}
                        </th>
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium  whitespace-nowrap "
                        >
                          {moment(order.createdAt).format("LT")}
                        </th>
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium  whitespace-nowrap "
                        >
                          {order.order_number}
                        </th>
                        <td className="px-6 py-4">{order.price}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {_customLabel(
                            order.address_status,
                            order.address_status === "INVALID"
                              ? "ERROR"
                              : "SUCCESS"
                          )}
                        </td>
                        <td className="px-6 py-4">{order.shop}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
          {paginatedData?.length > 0 ? null : (
            <p className="text-center w-full py-4">No Results</p>
          )}
          {showPagination ? (
            <div className="py-8 flex justify-center ">
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginatedData={paginatedData}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
