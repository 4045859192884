const CustomLoader = () => {
  return (
    <div
      className={`text-white bg-blue-800/50 fixed top-0 left-0 flex__center z-50 h-screen w-full`}
    >
      {`Loading.......`}
    </div>
  );
};

export default CustomLoader;
