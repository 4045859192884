import { useForm } from "react-hook-form";
import ThemeContainer from "../../components/layout/ThemeContainer";
import TextError from "../../components/shared/TextError";
import { Link, useNavigate } from "react-router-dom";
import { sendNotification } from "../../utils/notifications";
import { useMutation } from "react-query";
import { registerUser } from "../../services/api/auth";

const SignupPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["register"],
    mutationFn: async (data: any) => {
      let res = await registerUser(data);
      if (res?.status === 201) {
        sendNotification("success", res.data.message);
        navigate("/login");
      } else if (res?.status === 200 && res?.data?.CODE === "NOT_AUTHORIZED") {
        sendNotification("error", "You are not allowed to signup.");
      } else if (res?.status === 200 && res?.data?.CODE === "ALREADY_EXIST") {
        sendNotification("warning", "User is already exist.");
      } else {
        sendNotification("warning", res?.response?.data?.message);
      }
    },
  });

  const onSubmit = async (data: any) => {
    if (!data) return;
    mutate(data);
  };

  return (
    <ThemeContainer>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="min-h-[500px] text-black p-10 rounded-md bg-white shadow-xl max-w-[450px] w-full"
      >
        <h1 className="text-3xl mb-10">Signup Form</h1>
        <div className="mb-6 flex flex-col">
          <label>Username</label>
          <input
            type="text"
            {...register("name", {
              required: true,
              validate: (value) => isNaN(value),
            })}
            className="form__input mb-4"
            autoComplete="off"
            spellCheck={false}
          />
          {errors.name && errors.name.type === "required" && (
            <TextError color="dark" msg="Username is required" />
          )}
          {errors.name && errors.name.type === "validate" && (
            <TextError color="dark" msg="Numbers are not allowed" />
          )}
        </div>
        <div className="form-control mb-6 flex flex-col">
          <label>Email</label>
          <input
            type="text"
            {...register("email", {
              required: true,
              pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
            })}
            className="form__input mb-4"
            autoComplete="off"
            spellCheck={false}
          />
          {errors.email && errors.email.type === "required" && (
            <TextError color="dark" msg="Email is required." />
          )}
          {errors.email && errors.email.type === "pattern" && (
            <TextError color="dark" msg="Email is not valid." />
          )}
        </div>
        <div className="form-control mb-6 flex flex-col">
          <label>Password</label>
          <input
            type="password"
            {...register("password", {
              required: true,
            })}
            className="form__input mb-4"
            autoComplete="off"
            spellCheck={false}
          />
          {errors.password && errors.password.type === "required" && (
            <TextError color="dark" msg="Password is required." />
          )}
        </div>{" "}
        <div className="mb-6 flex flex-col">
          <label>Contact Number</label>
          <input
            type="text"
            {...register("phone", {
              required: true,
              maxLength: 10,
              minLength: 10,
            })}
            className="form__input mb-4"
            autoComplete="off"
            spellCheck={false}
          />
          {errors.phone && errors.phone.type === "required" && (
            <TextError color="dark" msg="Contact number is required" />
          )}
          {errors.phone &&
            (errors.phone.type === "maxLength" ||
              errors.phone.type === "minLength") && (
              <TextError
                color="dark"
                msg="Contact number must contain 10 digits."
              />
            )}
        </div>
        <button type="submit" className="custom__button !w-full mb-10">
          {isLoading ? "Loading..." : "Submit"}
        </button>
        <p className="text-sm text-center font-normal">
          Already have an account ?{" "}
          <Link to="/login">
            <span className="text-black font-medium">Signin</span>
          </Link>
        </p>
      </form>
    </ThemeContainer>
  );
};

export default SignupPage;
