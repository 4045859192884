import React from "react";

type Props = {
  id: string;
  label?: string;
  options: any;
  handleChange: any;
  value: string;
};

export default function SelectItem({
  id,
  label,
  options,
  handleChange,
  value,
}: Props) {
  return (
    <div key={id}>
      {label && (
        <label
          htmlFor="countries"
          className="block mb-2 text-sm dark:text-white"
        >
          {label}
        </label>
      )}
      <select
        id={id}
        value={value}
        className="bg-white border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer"
        onChange={(event) => handleChange(event, id)}
      >
        {options?.map((el: any, index: number) => {
          return (
            <option key={index} value={el?.value} className="cursor-pointer">
              {el.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}
