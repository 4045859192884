import { config } from "../config/index";

export const storeAccessTokenLS = (accessToken: string) => {
  if (config.ACCESS_TOKEN_KEY)
    return localStorage.setItem(config.ACCESS_TOKEN_KEY, accessToken);
};

export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const removeAccessToken = () => {
  return localStorage.removeItem("access_token");
};

export const storeRefreshTokenLS = (refreshToken: string) => {
  if (config.REFRESH_TOKEN_KEY)
    return localStorage.setItem(config.REFRESH_TOKEN_KEY, refreshToken);
};
export const getRefreshToken = () => {
  if (config.REFRESH_TOKEN_KEY)
    return localStorage.getItem(config.REFRESH_TOKEN_KEY);
};

export const formattedDate = (dateTime: number | Date | undefined) => {
  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return dateFormatter.format(dateTime);
};

export function formatDate(date: any, locale: any) {
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return date.toLocaleDateString(locale ?? "en-US", options);
}

// storing store in localstorage
export const setItemsIntoLC = (key: string, item: string) => {
  return localStorage.setItem(key, item);
};

export const getItemsFromLC = (key: string) => {
  return localStorage.getItem(key);
};
export const removeItemsFromLC = (key: string) => {
  return localStorage.removeItem(key);
};
