import { SidebarItem } from "./SideNavigation";
import { RxDashboard } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import SideNavigation from "./SideNavigation";
import { activateStore } from "../../../services/api/auth";
import { useEffect, useState } from "react";
import { setItemsIntoLC } from "../../../utils/helper";
import { useAuth } from "../../../context/authContext";
import SelectItem from "../../shared/SelectItem";
import CustomLoader from "../../Loader";
import UserMenu from "../UserMenu";
import { MdImportExport } from "react-icons/md";
import { TbUsersPlus } from "react-icons/tb";
import { UserAddModal } from "../../user/UserAddModal";
import { MdOutlineEmail } from "react-icons/md";

const MENU_ITEMS = [
  {
    id: "dashboard",
    title: "Dashboard",
    url: "/dashboard",
    icon: <RxDashboard size={20} />,
    isAdmin: true,
    isUser: true,
  },
  {
    id: "orders",
    title: "Orders History",
    url: "/orders-history",
    icon: <MdImportExport size={22} />,
    isAdmin: false,
    isUser: true,
  },
  {
    id: "authorized-users",
    title: "Authorized Users",
    url: "/authorized-users",
    icon: <TbUsersPlus size={20} />,
    isAdmin: true,
    isUser: false,
  },
  {
    id: "email-settings",
    title: "Email Settings",
    url: "/email-settings",
    icon: <MdOutlineEmail size={20} />,
    isAdmin: false,
    isUser: true,
  },
];

export default function MainLayout({ children }: any) {
  const route = useLocation().pathname;
  const navigate = useNavigate();
  const { updateUser, authUser, setAuthUser, IS_ADMIN } = useAuth();
  const [selectedStore, setSelectedStore] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setSelectedStore(authUser?.shop);
  }, [authUser?.shop]);

  const updateStore = async (shop: any) => {
    setIsLoading(true);
    if (shop) {
      const storeId = authUser?.stores?.find(
        (store: any) => store.shop === shop
      )?._id;

      if (storeId) {
        const response = await activateStore(storeId);
        if (response?.status === 200 && response?.data?.code === "SUCCESS") {
          setItemsIntoLC("store", shop);
          setAuthUser({ ...authUser, shop: undefined });
          updateUser();
        }
      }
    }
    setIsLoading(false);
  };

  const handleStore = (event: any) => {
    const { value } = event.target;
    if (value) {
      setSelectedStore(value);
      updateStore(value);
    }
  };

  const options = authUser?.stores?.map((store: any) => {
    return {
      label: store?.shop,
      value: store?.shop,
    };
  });
  let FILTERD_NAVIGATION_MENU = MENU_ITEMS;
  if (IS_ADMIN) {
    FILTERD_NAVIGATION_MENU = MENU_ITEMS?.filter((menu) => menu?.isAdmin);
  } else {
    FILTERD_NAVIGATION_MENU = MENU_ITEMS?.filter((menu) => menu?.isUser);
  }

  return (
    <>
      {isLoading ? <CustomLoader /> : null}
      {showModal ? (
        <UserAddModal showModal={showModal} setShowModal={setShowModal} />
      ) : null}
      <section className="min-h-100 w-full flex relative">
        <SideNavigation>
          {FILTERD_NAVIGATION_MENU?.map((menu) => {
            return (
              <SidebarItem
                key={menu?.id}
                icon={menu?.icon}
                text={menu?.title}
                alert
                url={menu?.url}
                active={route === menu?.url ? true : false}
              />
            );
          })}
        </SideNavigation>

        <main className="w-full min-h-screen px-4 py-10 sm:p-10 overflow-y-auto max-h-screen bg-slate-50">
          <div className="min-w-xxl mx-auto flex justify-center items-center flex-col ">
            <div className="w-full flex justify-end mb-20">
              <div className="flex gap-5">
                {authUser?.shop && authUser?.stores?.length > 0 && !IS_ADMIN ? (
                  <SelectItem
                    id={"status"}
                    value={selectedStore}
                    options={options}
                    handleChange={handleStore}
                  />
                ) : null}

                {IS_ADMIN ? (
                  <button
                    type="button"
                    className="custom__button
          "
                    onClick={() => {
                      setShowModal((prev) => !prev);
                    }}
                  >
                    Add User
                  </button>
                ) : (
                  <button
                    type="button"
                    className="custom__button
          "
                    onClick={() => {
                      navigate("/verification");
                    }}
                  >
                    Add Store
                  </button>
                )}

                {(authUser?.name && authUser?.shop) || !IS_ADMIN ? (
                  <UserMenu />
                ) : null}
              </div>
            </div>
            <div className={`w-full sm:max-w-5xl`}>{children}</div>
          </div>
        </main>
      </section>
    </>
  );
}
