import React, { createContext, useState, useContext, useEffect } from "react";
import { getUser } from "../services/api/user";
import { removeItemsFromLC } from "../utils/helper";
import { config } from "../config";

type AuthStateTypes = {
  isLoggedIn: boolean;
  userLogout: () => void;
  authUser: {
    _id?: any;
    name: string;
    email?: string;
    shop?: string;
    access_token?: string;
    stores: any;
    storeId: string;
    role: string;
  };
  setAuthUser: any;
  updateUser: any;
  isStoreLinked: boolean;
  IS_ADMIN: boolean;
};

const defaultContextValues: AuthStateTypes = {
  isLoggedIn: false,
  userLogout: () => {},
  authUser: {
    _id: "",
    name: "",
    email: "",
    shop: "",
    access_token: "",
    stores: [],
    storeId: "",
    role: "user",
  },
  setAuthUser: () => {},
  updateUser: () => {},
  isStoreLinked: false,
  IS_ADMIN: false,
};

const AuthContext = createContext(defaultContextValues);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [authUser, setAuthUser] = useState({
    _id: "",
    name: "",
    email: "",
    shop: "",
    access_token: "",
    stores: [],
    storeId: "",
    role: "user",
  });

  const userLogout = async () => {
    removeItemsFromLC(config.ACCESS_TOKEN_KEY);
    localStorage.removeItem(config.STORE);
    setAuthUser({
      _id: "",
      name: "",
      email: "",
      shop: "",
      access_token: "",
      stores: [],
      storeId: "",
      role: "",
    });
  };

  let isLoggedIn = localStorage.getItem("access_token") ? true : false;

  const updateUser = async () => {
    const response = await getUser();
    if (response?.status === 200) {
      let active_store: any = {};
      const activeStore = response?.data?.user?.stores?.find((store: any) => {
        if (store?.active == true) {
          return store;
        }
      });

      if (!activeStore) {
        active_store = response?.data?.user?.stores[0];
      } else {
        active_store = activeStore;
      }

      setAuthUser({
        ...response?.data?.user,
        shop: active_store?.shop,
        access_token: activeStore?.access_token,
        storeId: activeStore?._id,
      });
    } else if (response.status === 400 && response?.code === "NOT_FOUND") {
      userLogout();
    }
  };

  useEffect(() => {
    updateUser();
  }, [isLoggedIn]);

  const isStoreLinked = authUser?.shop ? true : false;
  const IS_ADMIN = authUser.role === "admin" ? true : false;
  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        userLogout,
        authUser,
        setAuthUser,
        updateUser,
        isStoreLinked,
        IS_ADMIN,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
