
type Props = {
  children: React.ReactNode;
};

const ThemeContainer = ({
  children
}: Props) => {

  return (
    <section
      className={`min-h-screen w-full flex__center`}
    >
        {children}
    </section>
  );
};

export default ThemeContainer;
