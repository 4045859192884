import { Navigate, Outlet, useLocation } from "react-router-dom";
import MainLayout from "../components/layout/main-layout";
import { ToastContainerNotification } from "../utils/notifications";
import VerificationPage from "../views/pages/VerificationPage";
import Header from "../components/layout/Header";

const ProtectedRoutes = () => {
  const isAuthenticated = localStorage.getItem("access_token");

  const route = useLocation()?.pathname;

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {route === "/verification" ? (
        <>
          <Header />
          <VerificationPage />
        </>
      ) : (
        <MainLayout>
          <Outlet />
        </MainLayout>
      )}
      <ToastContainerNotification />
    </>
  );
};

export default ProtectedRoutes;
