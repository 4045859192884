import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import TextError from "../../components/shared/TextError";
import ThemeContainer from "../../components/layout/ThemeContainer";
import { sendNotification } from "../../utils/notifications";
import {
  setItemsIntoLC,
  storeAccessTokenLS,
  storeRefreshTokenLS,
} from "../../utils/helper";
import { useAuth } from "../../context/authContext";
import { loginUser } from "../../services/api/auth";
import { useMutation } from "react-query";

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { updateUser } = useAuth();

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["login"],
    mutationFn: async (data: any) => {
      let res = await loginUser(data);
      if (res?.status === 200) {
        if (res.data?.CODE === "NOT_AUTHORIZED") {
          sendNotification("error", "You are not allowed to signup.");
        }
        updateUser();
        storeAccessTokenLS(res.data.access_token);
        storeRefreshTokenLS(res.data.refresh_token);

        const HAS_ADMIN_ROLE = res.data.user?.role === "admin" ? true : false;
        if (HAS_ADMIN_ROLE) {
          navigate("/dashboard");
        } else {
          const { store } = res?.data?.user;

          if (!store) {
            navigate("/verification");
          } else {
            setItemsIntoLC("store", store);
            navigate("/dashboard");
          }
        }
      } else {
        sendNotification("warning", res?.response?.data?.message);
      }
    },
  });

  const onSubmit = async (data: any) => {
    if (!data) return;
    mutate(data);
  };

  return (
    <ThemeContainer>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="min-h-[500px]  text-black bg-white  shadow-xl  p-10 rounded-md relative -z-0 max-w-[450px] w-full"
      >
        <h1 className="text-3xl mb-10">Login Form</h1>
        <div className="form-control mb-6 flex flex-col">
          <label>Email</label>
          <input
            type="text"
            {...register("email", {
              required: {
                value: true,
                message: "Email is required.",
              },
              pattern: {
                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                message: "Email is not valid.",
              },
            })}
            autoComplete="off"
            spellCheck={false}
            className="form__input mb-4"
          />
          {errors.email && (
            <TextError color="dark" msg={errors.email.message} />
          )}
        </div>
        <div className="form-control mb-6 flex flex-col">
          <label>Password</label>
          <input
            type="password"
            {...register("password", {
              required: {
                value: true,
                message: "Password is required.",
              },
            })}
            autoComplete="off"
            spellCheck={false}
            className="form__input mb-4"
          />
          {errors.password && (
            <TextError color="dark" msg={errors.password.message} />
          )}
        </div>
        <button type="submit" className="mb-10 custom__button !w-full">
          {isLoading ? "Loading..." : "Submit"}
        </button>

        <p className="text-sm text-center font-normal">
          Dont't have an account ?{" "}
          <Link to="/">
            <span className="text-black font-medium">Signup</span>
          </Link>
        </p>
      </form>
    </ThemeContainer>
  );
};

export default LoginPage;
