import { useQuery } from "react-query";
import api from "../../utils/axios";
import CustomLoader from "../../components/Loader";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { CustomModal } from "../../components/shared/CustomModal";

type PaginationProps = {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  paginatedData: any;
};
const itemsPerPage = 10;
const Pagination = ({
  currentPage,
  setCurrentPage,
  paginatedData,
}: PaginationProps) => {
  const hanldePrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };
  return (
    <nav aria-label="Page navigation example">
      <div className="flex items-center -space-x-px h-8 text-sm">
        <button disabled={currentPage === 1} onClick={hanldePrevious}>
          <a
            href="#"
            className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 "
          >
            <span className="sr-only">Previous</span>
            <svg
              className="w-2.5 h-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
          </a>
        </button>
        <span>
          <a
            href="#"
            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            {currentPage}
          </a>
        </span>
        <button
          onClick={handleNext}
          disabled={paginatedData?.length < itemsPerPage}
        >
          <a
            href="#"
            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
          >
            <span className="sr-only">Next</span>
            <svg
              className="w-2.5 h-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </a>
        </button>
      </div>
    </nav>
  );
};

export const AuthorizedUsersPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [emailId, setEmailId] = useState("");

  const { data: authorizedUsers, isLoading } = useQuery({
    queryKey: ["authorized_users"],
    queryFn: async () => {
      const resp = await api.get(`/auth/users`);
      if (resp?.data?.CODE === "SUCCESS") {
        return resp?.data.users;
      }
      return [];
    },
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = authorizedUsers?.slice(startIndex, endIndex);

  const showPagination =
    authorizedUsers && authorizedUsers.length > itemsPerPage;

  const handleDeleteUser = (id: string) => {
    if (!id) return;
    setShowModal(true);
    setEmailId(id);
  };
  if (isLoading) return <CustomLoader />;
  return (
    <>
      {showModal ? (
        <CustomModal
          showModal={showModal}
          setShowModal={setShowModal}
          emailId={emailId}
        />
      ) : null}
      <div className="flex justify-center items-center flex-col h-full ">
        <div className="w-full ">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-black dark:text-gray-400">
              <thead className="text-xs text-black uppercase bg-gray-50">
                <tr className="bg-secondary">
                  <th scope="col" className="px-6 py-3">
                    S.no
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-black">
                {paginatedData?.length > 0
                  ? paginatedData.map((user: any, index: number) => {
                      return (
                        <tr className="bg-white border-b" key={user._id}>
                          <td className="px-6 py-4"> {index + 1}</td>
                          <td className="px-6 py-4"> {user.email}</td>
                          <td className="px-6 py-4">
                            <MdDelete
                              size={22}
                              color="red"
                              className="cursor-pointer"
                              onClick={() => handleDeleteUser(user?._id)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            {paginatedData?.length > 0 ? null : (
              <p className="text-center w-full py-4">No Results</p>
            )}
            {showPagination ? (
              <div className="py-8 flex justify-center ">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  paginatedData={paginatedData}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
