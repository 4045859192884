import { useAuth } from "../../context/authContext";

export default function DashboardPage() {
  const { authUser, IS_ADMIN } = useAuth();

  return (
    <div className="flex justify-center items-center flex-col h-full ">
      <div className="w-full text-center">
        {authUser?.name ? (
          <>
            <h1 className="text-4xl font-semibold mb-3 text-gray-400">
              Hi ! Welcome back,
              <span className="text-black">{authUser?.name}</span>{" "}
            </h1>
          </>
        ) : null}
        {!authUser?.shop && !IS_ADMIN ? (
          <p className="text-gray-400">You have not added store yet.</p>
        ) : null}
      </div>
    </div>
  );
}
