import api from "../../utils/axios";

export const registerUser = async (body: {
  name: string;
  phone: number;
  email: string;
  password: string;
}): Promise<any> => {
  try {
    let response = await api.post(`/auth/register`, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const loginUser = async (body: {
  email: string;
  password: string;
}): Promise<any> => {
  try {
    let response = await api.post(`/auth/login`, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const refreshTokenApi = async (body: {
  refresh_token: string;
}): Promise<any> => {
  try {
    const response = await api.post("/auth/refresh-token", body);
    return response;
  } catch (error) {
    return error;
  }
};

export const verifyCredentials = async (body: {
  store: string;
  access_token: string;
}): Promise<any> => {
  try {
    const response = await api.post("/auth/store/verification", body);
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchStoreConfiguration = async (body: any): Promise<any> => {
  try {
    let response = await api.post(`/auth/store/configuration`, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchProducts = async (
  keyword: any,
  rating: any,
  currency: any
): Promise<any> => {
  try {
    let response = await api.get(
      `/scrapfly/request-products?keyword=${keyword}&rating=${rating}&currency=${currency}&page=1`
    );
    // let response = await api.get(`/scrapfly/request-products`);
    return response;
  } catch (error) {
    return error;
  }
};

// fetching user stores
export const fetchUserStores = async (): Promise<any> => {
  try {
    let response = await api.get(`/auth/user-stores`);
    return response;
  } catch (error) {
    return error;
  }
};

// fetching user stores
export const activateStore = async (storeId: string): Promise<any> => {
  try {
    let response = await api.patch(`/auth/activate-store/${storeId}`);
    return response;
  } catch (error) {
    return error;
  }
};

// admin can add user emails only those will be allowed to login
export const addUser = async (body: { email: string }): Promise<any> => {
  try {
    let response = await api.post(`/auth/user/add`, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const addEmail = async (body: {
  sendgrid_email: string;
  sendgrid_api_key: string;
}, shop: string | null): Promise<any> => {
  try {
    let response = await api.post(`/auth/email/add?shop=${shop}`, body);
    return response;
  } catch (error) {
    return error;
  }
};
